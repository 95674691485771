<template>
  <div style="background-color: #FFF; min-height: 100vh;">
    <!-- <a id="logo" href="https://alvarotrigo.com/fullPage/" alt="fullPage homepage">Enercred</a> -->
    <div class="text-xs-center" v-if="loading_inicio">
      <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
    </div>
    <div id="app" v-if="!loading_inicio">
      <!-- <ul id="menu" style="background-color:#000"></ul> -->
      <v-container fluid grid-list-md style="">
        <v-layout row wrap>
          <!-- logo  mobile-->
          <div class="hidden-md-and-up" style="padding: 3%;">
            <a
              href="https://enercred.com.br"
              target="_blank"
              alt="Enercred - Energia Limpa e Barata"
            >
              <img
                src="../../assets/logo.svg"
                width="150px"
                style="padding: 5px"
                alt="Enercred - Energia Limpa e Barata"
                class="src"
              />
            </a>
            <!-- Detalhe da assinatura para Mobile -->
            <v-flex xs12 sm12 md6 class="hidden-md-and-up pt-3">
              <cpnSteps :steps="{steps: 4, e1: 2}"/>
              <!-- <div class="pb-5 text-primary text--darken-3 text--large text-uppercase"></div> -->
              <!-- <cpnOrderDetail :lead="lead" /> -->
              <cpnProposalDetail :lead="lead" />
            </v-flex>
          </div>
          <v-layout row wrap align-center justify-center fill-height style="height: 100vh; background: #efefef">
            <!-- Formulario de registro -->
            <v-flex d-flex xs12 sm12 md6 style="text-align: center">
              <div class="form_conta">
                <div class style="padding: 0">
                  <cpnSteps  class="hidden-sm-and-down" :steps="{steps: 4, e1: 2}"/>
                  <!-- <div
                    class="headline hidden-sm-and-up"
                    style="padding-bottom: 15px; color: #808080"
                  >ASSINATURA DE ENERGIA LIMPA</div> -->
                  <!-- <div
                    class="display-1 hidden-sm-and-down"
                    style="padding-bottom: 15px; color: #808080"
                  >ASSINATURA DE ENERGIA LIMPA</div> -->
                  <div class="red--text pb-4 hidden-sm-and-down">Os dados a seguir devem ser do titular da conta de energia.</div>
                  <v-form ref="form" v-model="valid"  class="form_class">
                    <!-- CPF -->
                    <v-flex xs12>
                      <v-text-field
                        type="tel"
                        v-model="cpf"
                        label="CPF do titular da conta"
                        mask="###.###.###-##"
                        name="cpf"
                        :rules="[
                          v => !!v || 'Por favor, informe seu CPF!',
                          v => this.validaCPF(this.cpf) === true || 'CPF inválido'
                        ]"
                        required
                      ></v-text-field>
                    </v-flex>
                    <!-- Documento -->
                    <v-flex xs12>
                      <v-text-field
                        v-model="ID_document"
                        name="ID_document"
                        required
                        label="RG do titular da conta"
                        :rules="[
                          v => !!v || 'Por favor, informe o documento do titular da conta!'
                        ]"
                      ></v-text-field>
                    </v-flex>
                    <v-layout>
                      <!-- Numero de Instalação -->
                      <v-flex xs8 sm8 md8>
                        <v-text-field
                          v-model="installation_number"
                          label="Nº de Instalação (ver conta da Cemig)"
                          name="installation_number"
                          :rules="[
                            v => this.validaUC(this.installation_number) === true || 'Número de instalação inválido'
                          ]"
                        ></v-text-field>
                      </v-flex>
                      <v-flex xs4 sm4 md4>
                        <v-radio-group
                        v-model="installation_number"
                        :rules="[
                          v => this.validaUC(this.installation_number) === true || 'Número de instalação inválido'
                        ]"
                      >
                        <v-radio :key="1" :label="`Não sei`" value="não sei"></v-radio>
                      </v-radio-group>
                      </v-flex>
                    </v-layout>
                    <!-- Senha
                    <v-flex xs12>
                      <v-text-field
                        v-model="senha"
                        type="password"
                        label="Senha"
                        name="senha"
                        :rules="[
                          v => !!v || 'Por favor, informe uma senha!',
                          v => 
                            v && v.length >= 8 ||
                            'Sua senha deve possuir 8 caracteres ou mais!'
                        ]"
                        required
                      ></v-text-field>
                    </v-flex>
                    -->
                    <!-- Confirmar Senha -->
                    <!--
                    <v-flex xs12>
                      <v-text-field
                        type="password"
                        label="Confirmar Senha"
                        name="confirmar_senha"
                        :rules="[
                          v => !!v || 'Por favor, confirme sua senha!',
                          v =>
                            v && v.length >= 8 ||
                            'Sua senha deve possuir 8 caracteres ou mais!',
                            v => v === this.senha || 'Senhas não combinam',

                        ]"
                        required
                      ></v-text-field>
                    </v-flex> -->
                    <!-- Titular ou familiar -->
                    <header style="text-align: left">Você é o titular da conta de luz?</header>
                    <v-radio-group
                      v-model="titular"
                      :rules="[
                        v => !!v || 'Por favor, informe sobre o titular da conta!'
                      ]"
                    >
                      <v-radio :key="1" :label="`Sou titular da Conta`" value="Sim"></v-radio>
                      <v-radio :key="2" :label="`Sou familiar do titular`" value="Familiar"></v-radio>
                    </v-radio-group>
                    <!-- Forma de Pagamento -->
                    <!-- <header style="text-align: left">Forma de Pagamento</header>
                    <v-radio-group
                      v-model="payment_type"
                      :rules="[
                        v => !!v || 'Por favor, informe como deseja pagar sua energia limpa!'
                      ]"
                    >
                      <v-radio :key="1" :label="`Boleto`" value="boleto"></v-radio>
                      <v-radio :key="2" :label="`Cartão de Crédito`" value="credit_card"></v-radio>
                    </v-radio-group> -->

                    <!-- Termo de Compromisso -->
                    <!-- <v-flex xs12 style="text-align: left; padding: 0 0 0 7px; margin: 0">
                      <v-btn @click="dialog = true" color="primary">Ler Contrato</v-btn>
                    </v-flex>
                    <v-flex xs12>
                      <v-checkbox
                        v-model="termo"
                        @click="dialog = true;"
                        label="Aceito o Contrato de Aluguel"
                        :rules="[
                          v =>
                            !!v ||
                            'É necessário aceitar o contrato de aluguel para concluir o cadastro!'
                        ]"
                      ></v-checkbox>
                    </v-flex> -->
                    <!-- Botao solicitar proposta -->
                    <div class="my-2">
                      <v-btn
                        :loading="loading"
                        :disabled="!valid || loading"
                        block
                        @click.prevent="validate()"
                        large
                        style="background-color: rgb(9, 95, 150)"
                      >Solicitar proposta</v-btn>
                      <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=1928329&conversionId=1958089&fmt=gif" />
                    </div>
                  </v-form>
                  <!-- <v-progress-circular v-if="loading" :size="50" color="teal" indeterminate></v-progress-circular> -->

                  <br />
                </div>
              </div>
            </v-flex>
            <!-- Enercred info -->
            <v-flex xs12 sm12 md6 style="text-align: center;">
              <div class="step2_esquerda" style="padding-top: 0">
                <div class="hidden-sm-and-down">
                  <a
                    href="https://enercred.com.br"
                    target="_blank"
                    alt="Enercred - Energia Limpa e Barata"
                  >
                    <img
                      src="../../assets/logo.svg"
                      width="180px"
                      style="padding: 5px"
                      alt="Enercred - Energia Limpa e Barata"
                      class="src"
                    />
                  </a>
                </div>
                <div>
                  <!-- <div class="hidden-sm-and-down pt-3 text-primary text--darken-5 text--large text-uppercase">Veja quanto você economiza alugando uma fração de nossas usinas solares</div> -->
                  <!-- <cpnOrderDetail :lead="lead" class="hidden-sm-and-down pt-5" /> -->
                  <cpnProposalDetail :lead="lead" class="hidden-sm-and-down pt-5"/>
                </div>
                <div class="hidden-sm-and-down pt-5"></div>
              </div>
            </v-flex>
          </v-layout>
        </v-layout>
      </v-container>
    </div>
    <div class="text-xs-center">
      <v-dialog v-model="dialog" width="500" scrollable>
        <v-card>
          <v-card-title class="headline grey lighten-2 pa-3" primary-title>Contrato de Aluguel</v-card-title>

          <v-card-text class="pa-3">
            <cpnTermsOfUse />
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="ml-auto">
            <v-spacer></v-spacer>
            <v-btn block color="secondary" @click="acceptUseOfTerms">ALUGAR</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
// import { enviarEmail } from "../../services/sendmail"
import { saveAnalytics } from "@/services/save_analytics"
// import { setRDConversion } from "@/services/rdstation";
import { STORE_LEAD, GET_LEAD } from "../../graphql/leads"
import { STORE_USER } from "../../graphql/users"
import { GET_PEOPLE } from "../../graphql/people"
import ProposalDetail from "../../components/order/ProposalDetail"
import TermsOfUse from "../../components/termsOfUse/TermsOfUse"
import { SET_CONVERSION } from "../../graphql/rdstation"
import Steps from '../../components/core/Steps'
import { getIP } from '../../services/getIP'
// import { storeUser } from '../../services/firebase/user'

export default {
  components: {
    cpnProposalDetail: ProposalDetail,
    cpnTermsOfUse: TermsOfUse,
    cpnSteps: Steps
  },
  data() {
    return {
      loading_inicio: true,
      dialog: false,
      loading: false,
      cpf: "",
      titular: "",
      senha: "",
      payment_type: "",
      installation_number:"",
      installation_number_nsei: "",
      ID_document: "",
      termo: false,
      valid: false,
      isProd: false,
      lead: {},
      ipinfo: {}
    }
  },
  computed: {
    user() {
      return this.$store.getters["getUser"]
    },
    ...mapGetters(["getUser"])
  },
  created() {
    this.loading_inicio = true
    this.isProd = process.env.NODE_ENV === "production"
    if(this.$route.query.email) {
      const email = this.$route.query.email
      this.getLead(email)
        .then(lead => {
          this.setLead(lead.data.lead)
          this.loading = false
          this.loading_inicio = false
        })
        .catch(() => {
          console.error('Email não encontrado: ', email)
          this.loading = false
          this.loading_inicio = false
        })
    } else {
      const lead = JSON.parse(localStorage.getItem("lead"))
      // console.log('lead: ', lead)
      if(lead && lead.email) {
        this.setLead(lead)
      }
      this.loading = false
      this.loading_inicio = false
    }
    // this.lead = JSON.parse(localStorage.getItem("lead"))
    // if (this.lead) {
    //   // this.average_consumption = this.lead ? this.lead.average_consumption : ""
    //   this.cpf = this.lead.cpf
    //   this.titular = this.lead.titular
    //   this.password = this.lead.password
    //   this.termouso = this.lead.termouso
    //   this.gender = this.lead.gender
    //   this.date_of_birth = this.lead.date_of_birth
    //   this.ID_document = this.lead.ID_document
    // }
  },
  methods: {
    async getLead(email) {
      return await this.$apollo.query({
        query: GET_LEAD,
        variables: {
          email: email
        }
      })
    },
    setLead(lead) {
      this.lead = lead
      this.cpf = lead.cpf
      this.titular = lead.titular
      this.password = lead.cpf
      this.termouso = false
      this.gender = lead.gender
      this.date_of_birth = lead.date_of_birth
      this.installation_number = lead.installation_number
      this.ID_document = lead.ID_document
      this.payment_type = lead.payment_type || 'credit_card'
    },
    async validate() {
      let peopleName = ""
      let peopleGender = ""
      let peopleBirthdate = ""
      let peopleDocument = ""

      if (this.$refs.form.validate() && this.user && this.user.name && this.user.email) {
        this.loading = true
        const ipinfo = await getIP()
        // console.log('ip: ', ipinfo)
          // .then(response => {
          //   this.ipinfo = response.data
          //   console.log(response.data)
          // })
          // .catch(error => {
          //   console.error('Erro ao recuperar IP do usuário. Erro: ', error)
          // })
        await this.getPeople(this.cpf).then(people => {
          if (
            typeof people !== "undefined" &&
            people !== null &&
            typeof people.data !== "undefined" && people.data !== null &&
            typeof people.data.people !== "undefined" && people.data.people !== null &&
            typeof people.data.people.Result[0] !== "undefined" && people.data.people.Result[0] !== null &&
            typeof people.data.people.Result[0].BasicData !== "undefined" &&
              people.data.people.Result[0].BasicData !== null &&
            typeof people.data.people.Result[0].BasicData.AlternativeIdNumbers !== "undefined" &&
              people.data.people.Result[0].BasicData.AlternativeIdNumbers !== null
          ) {
            peopleName = people.data.people.Result[0].BasicData.Name
            peopleGender = people.data.people.Result[0].BasicData.Gender || ""
            peopleBirthdate = people.data.people.Result[0].BasicData.BirthDate || ""
            peopleDocument = people.data.people.Result[0].BasicData.AlternativeIdNumbers.RG || ""
          }
        })

        // Enviar email para novo cliente
        // const email_msg = {
        //   email: this.user.email,
        //   name: this.user.name,
        //   template_id: process.env.VUE_APP_TEMPLATE_EMAIL_CADASTRO,
        //   dynamic_template_data: {
        //     name: this.user.name.split(" ")[0],
        //     email: this.user.email
        //   }
        // }
        // enviarEmail(JSON.parse(JSON.stringify(email_msg)))
        this.user.name = peopleName ? peopleName : this.user.name
        
        const user = {
          ...this.user,
          cpf: this.cpf,
          titular: this.titular,
          installation_number: this.installation_number,
          password: this.cpf,
          termo_uso: 'Não',
          gender: peopleGender,
          date_of_birth: peopleBirthdate,
          ID_document: this.ID_document || peopleDocument,
          payment_type: this.payment_type || "credit_card",
          status: "REQUEST_PROPOSAL",
          // created_ipinfo: ipinfo.data
        }
        if(this.payment_type === 'boleto') {
          user.boleto_accepted_by =  "onboarding",
          user.boleto_ipinfo = ipinfo.data
        }

        this.$store.commit("storeUserState", user)

        this.storeLead(user, 8)
          .then(result => {
            if (result.data) {
              localStorage.setItem("lead", JSON.stringify(user))
            }
          })
          .catch(err => {
            this.loading = false
            console.error("Error: ", err)
          })

        this.storeUser(user)
        // storeUser(user)
          .then(() => {
            localStorage.setItem("newUser", JSON.stringify(user))
            // analytics
            if (this.isProd) {
              const analytics = {
                eventCategory: "onboard",
                eventAction: "REQUEST-PROPOSAL",
                eventLabel: "REQUEST-PROPOSAL",
                transaction_id: user.email,
                value: user.average_consumption,
                utm_source: this.lead.utm_source,
                utm_medium: this.lead.utm_medium,
                utm_campaign: this.lead.utm_campaign,
                boleto: false
              }
              saveAnalytics(analytics)
              const conversion = {
                event_type: "OPPORTUNITY",
                event_family: "CDP",
                payload: {
                  email: user.email,
                  funnel_name: "default"
                }
              }
              this.setRDConversion(conversion)
            }
            this.$router.push("/cadastrar/solicitacao-proposta")
            this.loading = false
            return true
          })
          .catch(error => {
            console.error("Erro ao criar usuário: ", error)
            this.loading = false
            return false
          })
      } else {
        alert("Desculpe, houve algum erro ao salvar, reiniciaremos o seu cadastro!")
        this.$router.push("cadastro")
      }
    },
    async getPeople(cpf) {
      return await this.$apollo.query({
        query: GET_PEOPLE,
        variables: {
          doc: cpf
        }
      })
    },
    acceptUseOfTerms() {
      this.termo = true
      this.dialog = false
    },
    storeUser(user) {
      const group = "customer"
      return this.$apollo.mutate({
        // Query
        mutation: STORE_USER,
        // Parameters
        variables: {
          email: user.email,
          name: user.completeName || user.name || "",
          cpf: user.cpf,
          phone: user.phone,
          group: group,
          profession: user.profession,
          marital_status: user.marital_status,
          installation_number: user.installation_number,
          marital_type: user.marital_type,
          gender: user.gender || "",
          ID_document: user.ID_document,
          status: user.status,
          date_of_birth: user.date_of_birth,
          payment_type: user.payment_type || "credit_card",
          // referral_balance: process.env.VUE_APP_REFERRAL_VALUE_INDICADO,
          // referral_reward: process.env.VUE_APP_REFERRAL_VALUE_INDICADOR,
          password: user.password,
          average_consumption: user.average_consumption,
          boleto_accepted_by: user.boleto_accepted_by,
          boleto_ipinfo: user.boleto_ipinfo,
          // signedcontract_ipinfo: user.signedcontract_ipinfo
        }
      })
    },
    storeLead(lead, step) {
      return this.$apollo.mutate({
        // Query
        mutation: STORE_LEAD,
        // Parameters
        variables: {
          id: lead.email,
          email: lead.email,
          name: lead.name,
          cpf: lead.cpf,
          phone: lead.phone,
          average_consumption: lead.average_consumption,
          step: step,
          classe: lead.classe,
          concessionaria: lead.concessionaria,
          titular: lead.titular,
          termouso: lead.termouso == true ? "Sim" : "Nao",
          zipcode: lead.zipcode,
          promo_code: lead.promo_code,
          utm_source: lead.utm_source,
          utm_medium: lead.utm_medium,
          utm_campaign: lead.utm_campaign,
          ID_document: lead.ID_document,
          profession: lead.profession,
          marital_status: lead.marital_status,
          marital_type: lead.marital_type,
          gender: lead.gender || ""
        }
      })
    },
    validaCPF(strCPF) {
      if (!strCPF) return ""
      var Soma
      var Resto
      Soma = 0
      if (strCPF == "00000000000") return false

      for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
      Resto = (Soma * 10) % 11

      if (Resto == 10 || Resto == 11) Resto = 0
      if (Resto != parseInt(strCPF.substring(9, 10))) return false

      Soma = 0
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
      Resto = (Soma * 10) % 11

      if (Resto == 10 || Resto == 11) Resto = 0
      if (Resto != parseInt(strCPF.substring(10, 11))) return false
      return true
    },
    validaUC(strUC) {
        // console.log('strUC: ', strUC)
        if (!strUC) return false
        if (strUC === 'não sei') return true
        if (strUC.trim() === '') return false
        if (strUC == "0000000000") return false
        if (strUC.substring(0,1) !== '3') return false
        if (strUC.length < 10) return false
        return true
    },
    setRDConversion(conversion) {
      return this.$apollo.mutate({
        // Query
        mutation: SET_CONVERSION,
        // Parameters
        variables: {
          event_type: conversion.event_type,
          event_family: conversion.event_family,
          payload: conversion.payload
        }
      })
    }
  },
  mounted() {
    this.loading_inicio = false
  }
}
</script>

<style scoped>
@charset "ISO-8859-1";
/* Reset CSS
 * --------------------------------------- */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  padding: 0;
  margin: 0;
}
a {
  text-decoration: none;
}

fieldset,
img {
  border: 0;
}
ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
caption,
th {
  text-align: left;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 100%;
  margin: 0;
  padding: 0;
}
q:before,
q:after {
  content: "";
}
abbr,
acronym {
  border: 0;
}
* {
  -webkit-box-sizing: border-box; /* Safari<=5 Android<=3 */
  -moz-box-sizing: border-box; /* <=28 */
  box-sizing: border-box;
}

/* Custom
 * --------------------------------------- */
html,
body {
  height: 100%;
  background-color: #095f96;
}
template {
  background-color: #095f96;
}
.area {
  height: 100px;
  width: 100px;
  background: red;
  margin: 10px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}
.content {
  height: 100px;
  width: 100px;
  display: table-cell;
  vertical-align: middle;
}
.header {
  font-size: 2em;
  height: 550px;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  display: table-cell;
  color: #fff;
}
.header span {
  font-size: 2em;
  color: #fff;
  font-weight: bold;
}
.header br {
  font-size: 4em;
  color: #fff;
  font-weight: bold;
}
.form_conta {
  height: 100vh;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  background-color: #fff;
  padding: 1% 5% 10% 5%;
  text-align: center;
  /* border-radius: 10px; */
}
.step2_esquerda {
  width: 95%;
  padding-left: 5%;
  padding-top: 10%;
  text-align: left;
}
/*
.form_class {
  padding-top: 100px;
  height: 550px;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  text-align: center;
}
*/
.section {
  position: relative;
  text-align: center;
}
#section-1 h2 {
  color: #fff;
  font-size: 10em;
  font-weight: 900;
}
#section-1 h1 {
  font-size: 2em;
  font-weight: 100;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  margin: 1.5em auto 1em auto;
  color: #00a8bb;
  padding-right: 30px;
  padding-left: 30px;
}
#section-1 li {
  display: inline-block;
  margin: 1.25em 0.3em;
}
.section-1-button {
  padding: 0.93em 1.87em;
  background: #00a8bb;
  border-radius: 5px;
  display: block;
  color: #fff;
}
#section-1 h2 {
  color: #fff;
  font-size: 10em;
  font-weight: 500;
}
#section-3 h1 {
  font-size: 2em;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  margin: 1.5em auto 1em auto;
  color: #00a8bb;
  padding-right: 30px;
  padding-left: 30px;
}
#section-3 li {
  display: inline-block;
  margin: 1.25em 0.3em;
}
.section-3-button {
  padding: 0.93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  display: block;
  color: #fff;
}
#section-3 .intro {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  width: 50%;
  padding: 0 3em;
}

h3 {
  font-size: 4em;
  /* text-align: center; */
  color: #fff;
  font-weight: bold;
}
h2 {
  font-size: 2em;
  /* text-align: center; */
  color: #fff;
  font-weight: bold;
}
#logo {
  position: fixed;
  top: 20px;
  left: 20px;
  color: #fff;
  font-weight: bold;
  z-index: 99;
  font-size: 1.9em;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
}
.progress-bar {
  margin: 10px 0;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.atencao {
  font-size: 1.5em;
  color: #00a8bb;
  font-weight: bold;
}

/* Menu
 * --------------------------------------- */
#menu-line {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 159px;
  height: 2px;
  background: #fff;
}

#menu {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 70;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  letter-spacing: 1px;
  font-size: 1.1em;
}
#menu li {
  display: inline-block;
  margin: 10px 0px;
  position: relative;
}
#menu a {
  color: #fff;
  padding: 0 1.1em 1.1em 1.1em;
}
#menu li.active a:after {
  content: "";
  margin: 0 1.1em 0 1.1em;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  display: block;
}

/* Actions buttons
 * --------------------------------------- */
.actions {
  position: fixed;
  bottom: 2%;
  margin: 0 auto;
  z-index: 99;
  left: 0;
  right: 0;
  text-align: center;
}
.actions li {
  display: inline-block;
  margin: 0.3em 0.3em;
}
.actions-button {
  padding: 0.73em 1.47em;
  background: rgba(53, 73, 94, 0.47);
  border-radius: 5px;
  display: block;
  color: #fff;
  cursor: pointer;
}

/* Twitter
 * --------------------------------------- */
.twitter-share i {
  vertical-align: middle;
  position: relative;
  top: 2px;
  display: inline-block;
  width: 38px;
  height: 14px;
  color: white;
  top: -4px;
  left: -2px;
  fill: #fff;
}
.twitter-share svg {
  height: 40px;
  margin-top: -10px;
}
.view-github {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 999;
  padding: 0.93em 1.87em;
  background: #35495e;
  border-radius: 5px;
  display: block;
  color: #fff;
}

.shell {
  width: calc(50% - 0px);
  display: inline-block;
  background-color: #fff;
  /*  background-image: url('../assets/eolica.png'); */
  background-position: center;
  border-radius: 0px 0px 0 0;
  text-align: left;
  margin-right: 0px;
}
</style>
